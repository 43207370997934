import * as React from "react";
import Tunisia from "@svg-maps/tunisia";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import "./destination-styles.css";
import SEO from "../../components/SEO";
import Header from "../../components/header";
import Newsletter from "../../components/newsletter";
import Footer from "../../components/Footer";
import BannerEffect from "../../themes/custom/jobs_front/assets/img/bannerEffect.png";
import TunisiaJobs from "../../assets/img/where-we-work.jpg";
import nationalLevel from "../../assets/img/where-we-work-national.jpg";

const WhereWeWork = () => {
  const [selected, setSelected] = React.useState();
  const [tooltipStyle, setTooltipStyle] = React.useState();
  const getLocationClassName = (location, index) => {
    // Generate Colors to necessary locations
    return `svg-map__location svg-map__location--${location.name}`;
  };
  const handleLocationMouseMove = (event) => {
    const localTooltipStyle = {
      display: "block",
      top: event.target.getBoundingClientRect().y + 10,
      left: event.target.getBoundingClientRect().x - 100,
    };
    setSelected(event.target.attributes.name.value);
    setTooltipStyle(localTooltipStyle);
  };
  const handleLocationMouseOut = () => {
    setSelected(null);
    setTooltipStyle({ display: "none" });
  };
  const showTooltip = (event) => {
    handleLocationMouseMove(event);
  };
  const hideTooltip = (event) => {
    handleLocationMouseOut(event);
  };
  return (
    <>
      <SEO
        title="Where we work | Visit Tunisia Project"
        description="Where we work"
      />
      <Header />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-banner">
            <div
              id="block-assistancetoentreprises"
              className="block block-block-content block-block-contentbbbe56a9-7411-4d30-b6b6-041089750357"
            >
              <div className="intern-banner">
                <div className="overlay">
                  <img src={BannerEffect} alt="" />
                </div>
                <img src={TunisiaJobs} alt="" />
                <div className="caption">
                  <h1 className="animate__animated animate__fadeInUp animate__slow">
                    <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                      Where We Work
                    </div>
                  </h1>
                  <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                    <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                      <p>
                        Supporting national, local, and international efforts{" "}
                        <span>to enhance Tunisia’s tourism potential</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <article
                data-history-node-id={48}
                role="article"
                className="node node--type-activities node--promoted node--view-mode-full"
              >
                <div className="node__content">
                  <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                    <div>
                      <section className="resources" id="grants">
                        <div className="container">
                          <h2
                            className="title-left aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              The USAID Visit Tunisia Activity is designed to
                              support all of Tunisia through national
                              initiatives, including developing international
                              source markets while supporting specific
                              destination development efforts
                            </div>
                          </h2>
                          <div
                            className="section-paragh aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="d-flex flex-md-row flex-column">
                              <div className="left-side">
                                <p>
                                  Visit Tunisia partners with government
                                  agencies, private enterprises, tourism
                                  associations, destination marketing agencies,
                                  and content creators to market Tunisia as a
                                  compelling tourism destination.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section
                        className="impact aos-animate"
                        data-aos="fade-up"
                      >
                        <div className="brick brick--type--impact brick--id--54 eck-entity container">
                          <span className="title-left">
                            <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                              Working at the National Level
                            </div>
                          </span>
                          <div
                            className="where-we-work-national"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <div>
                              <img
                                className="where-we-work-image"
                                style={{
                                  width: "500px",
                                  height: "420px",
                                  marginRight: "25px",
                                  marginTop: "25px",
                                }}
                                src={nationalLevel}
                                alt=""
                              />
                              <span
                                style={{
                                  width: "80%",
                                  fontSize: "10px",
                                  color: "#4187b1",
                                  fontStyle: "italic",
                                  lineHeight: "initial",
                                }}
                              >
                                Visit Tunisia kicked off the project by meeting
                                with the Ministry Of Tourism And Handicrafts
                                (MoTH), the National Tourism Board (ONTT), the
                                Ministry Of Culture, and industry associations.
                              </span>
                            </div>
                            <div>
                              <h2
                                style={{
                                  marginTop: "0px",
                                  width: "100%",
                                }}
                              >
                                Visit Tunisia works with the Government of
                                Tunisia and national tourism associations on key
                                national initiatives. <br /> <br /> These
                                include:
                              </h2>
                              <ul className="pl-0">
                                <li>
                                  <span>National Tourism Strategy 2030</span>
                                </li>
                                <li>
                                  <span>
                                    Tourism Industry Association Strengthening
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Quality, Safety, and Sustainability
                                    Standards
                                  </span>
                                </li>
                                <li>
                                  <span>Strategic Marketing Planning</span>
                                </li>
                                <li>
                                  <span>National Marketing Campaigns</span>
                                </li>
                                <li>
                                  <span>Public Awareness Campaigns</span>
                                </li>
                                <li>
                                  <span>Air Services Development</span>
                                </li>
                                <li>
                                  <span>Data-Driven Decision Making</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div>
                            <h3
                              style={{
                                marginTop: "0px",
                                width: "100%",
                                fontSize: "25px !important",
                              }}
                            >
                              In addition, many of our funding opportunities and
                              technical assistance programs are designed to
                              support tourism businesses, event and festival
                              organizers, and entrepreneurs across all of
                              Tunisia.
                              <br /> <br /> These include:
                            </h3>
                            <ul className="pl-0">
                              <li>
                                <span>Tourism Product Development</span>
                              </li>
                              <li>
                                <span>Event & Festival Development</span>
                              </li>
                              <li>
                                <span>
                                  Pathways to Professionalism (Workforce
                                  Training & Licensure)
                                </span>
                              </li>
                              <li>
                                <span>
                                  Pathways to Profitability (SME Training &
                                  Market Linkages)
                                </span>
                              </li>
                              <li>
                                <span>Microfinance Support</span>
                              </li>
                              <li>
                                <span>Investment Facilitation</span>
                              </li>
                            </ul>
                          </div>
                          <span className="title-left">
                            <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                              Working at the Destination Level
                            </div>
                          </span>
                          <div>
                            <h3
                              style={{
                                marginTop: "0px",
                                width: "100%",
                                fontSize: "25px !important",
                              }}
                            >
                              In addition to national initiatives, Visit Tunisia
                              is designed to support specific destinations
                              within Tunisia through a clustering approach of
                              tourism stakeholders conducting public-private
                              dialogue to create and implement destination
                              development plans.
                              <br /> <br /> These plans are designed to:
                            </h3>
                            <ul className="pl-0">
                              <li>
                                <span>
                                  Identify priorities for investment in tourism
                                  site infrastructure,
                                </span>
                              </li>
                              <li>
                                <span>
                                  Explore opportunities for public-private
                                  partnership concessions,
                                </span>
                              </li>
                              <li>
                                <span>
                                  Define existing and new events that will
                                  attract visitors,
                                </span>
                              </li>
                              <li>
                                <span>
                                  Improve destination governance and
                                  coordination,
                                </span>
                              </li>
                              <li>
                                <span>
                                  Create regional and destination marketing
                                  partnerships,
                                </span>
                              </li>
                              <li>
                                <span>
                                  Strengthen linkages between tourism service
                                  providers, and
                                </span>
                              </li>
                              <li>
                                <span>
                                  Improve tourism development coordination and
                                  communication between central and local
                                  governments
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div>
                            <h3 style={{ marginTop: "0px", width: "100%" }}>
                              A destination is defined by the UN World Tourism
                              Organization (UNWTO) as: “a physical space with or
                              without administrative and/or analytical
                              boundaries in which a visitor can spend an
                              overnight. It is the cluster (co-location) of
                              products and services, and of activities and
                              experiences along the tourism value chain and a
                              basic unit of analysis of tourism. A destination
                              incorporates various stakeholders and can network
                              to form larger destinations. It is also intangible
                              with its image and identity which may influence
                              its market competitiveness.”
                            </h3>
                          </div>
                          <br />
                          <br />
                          <div>
                            <h3 style={{ marginTop: "0px", width: "100%" }}>
                              Visit Tunisia assessed both attractions and
                              destinations throughout Tunisia in collaboration
                              with the ONTT’s regional tourism offices (CRTs)
                              while conducting regional workshops as part of the
                              national tourism strategy development process.
                              This assessment was combined with a desk analysis
                              of data available online of each potential
                              destination around the following areas.
                            </h3>
                          </div>
                          <div className="where-we-work-table">
                            <table>
                              <tr>
                                <th>Existing Tourism Offerings</th>
                                <th>Sufficient Infrastructure</th>
                                <th>Human Resources</th>
                                <th>Tourism Assets</th>
                                <th>Underserved Area</th>
                              </tr>
                              <tr>
                                <td>Products</td>
                                <td>Access</td>
                                <td>Workforce Skill Levels</td>
                                <td>Natural Attractions</td>
                                <td>Regional Development Index</td>
                              </tr>
                              <tr>
                                <td>Services</td>
                                <td>Transport</td>
                                <td>Training Localities</td>
                                <td>Cultural Attractions</td>
                                <td>Regional Efficiency</td>
                              </tr>
                              <tr>
                                <td>Experiences</td>
                                <td>Lodging</td>
                                <td>Opportunities to Expand Employment</td>
                                <td></td>
                                <td>Poverty Rate</td>
                              </tr>
                            </table>
                          </div>
                          <br />
                          <br />
                          <div>
                            <h3 style={{ marginTop: "0px", width: "100%" }}>
                              Based on this analysis, Visit Tunisia will support
                              the following destinations in the first few years.
                              Additional destinations may be considered for
                              destination development support in subsequent
                              years of the project.
                            </h3>
                          </div>
                          <div className="views-element-container">
                            <section className="work-location">
                              <div className="view map-work container view-where-we- view-id-where_we_ view-display-id-page_1 js-view-dom-id-e191b091d84ace7850aa54796a0489cde90aebecf640fe058909da74aaef1995">
                                <div className="simpleMaps map">
                                  <div id="map">
                                    <SVGMap
                                      map={Tunisia}
                                      onLocationMouseOver={showTooltip}
                                      locationClassName={getLocationClassName}
                                      onLocationMouseOut={hideTooltip}
                                    />
                                    <div
                                      className="map-tooltip"
                                      style={tooltipStyle}
                                    >
                                      {selected}
                                    </div>
                                  </div>
                                  <div className="card map-location-card">
                                    <div>
                                      <div className="card-top d-flex justify-content-between align-items-center">
                                        <span>Tabarka</span>
                                        <i className="fas fa-times" />
                                      </div>
                                      <div className="card-top d-flex justify-content-between align-items-center">
                                        <span>Kairouan</span>
                                        <i className="fas fa-times" />
                                      </div>
                                      <div className="card-top d-flex justify-content-between align-items-center">
                                        <span>Tozeur/Nafta</span>
                                        <i className="fas fa-times" />
                                      </div>
                                      <div className="card-top d-flex justify-content-between align-items-center">
                                        <span>Douz</span>
                                        <i className="fas fa-times" />
                                      </div>
                                      <div className="card-top d-flex justify-content-between align-items-center">
                                        <span>Tataouine</span>
                                        <i className="fas fa-times" />
                                      </div>
                                      <div className="card-top d-flex justify-content-between align-items-center">
                                        <span>Gabes/Matmata</span>
                                        <i className="fas fa-times" />
                                      </div>
                                      <div></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                          <div>
                            <span className="title-left">
                              <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                                Working at the International Level
                              </div>
                            </span>
                            <div>
                              <h3 style={{ marginTop: "0px", width: "100%" }}>
                                Through our head office in Tunis and in
                                collaboration with the ONTT’s international
                                offices abroad, Visit Tunisia also works to
                                increase country awareness and drive demand to
                                Tunisia through the design and implementation of
                                international marketing campaigns and
                                participation in international events.
                              </h3>
                            </div>
                            <div>
                              <h3 style={{ marginTop: "0px", width: "100%" }}>
                                Under the activity Air Services Development
                                Support, Visit Tunisia will assist the Ministry
                                of Transport and the Tunisian Civil Aviation and
                                Airports Authority to develop and cultivate
                                relationships with international airlines to
                                increase the amount and frequency of flights
                                into Tunisia.
                              </h3>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </main>
      <Newsletter />
      <Footer />
    </>
  );
};
export default WhereWeWork;
